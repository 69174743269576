import "core-js/modules/es.array.push.js";
import { useRouter } from "vue-router";
import { dataController, dataControllerdetail, dataControllertitle, upvote, userinfo } from "../../api/application/index";
export default {
  data() {
    return {
      list: [],
      titlelist: [],
      activeindex: 0,
      articleCategoryId: 1,
      flag: true,
      pageNum: 1,
      pageSize: 5,
      total: 0,
      info: {} //用户信息
    };
  },

  activated() {
    console.log(this.$store.state.imgurl);
    this.list = [];
    this.getuserinfo();
    window.addEventListener("scroll", this.scrollBottom, true);
  },
  methods: {
    getuserinfo() {
      userinfo().then(res => {
        console.log(res);
        if (res.code == 200) {
          this.info = res.data;
          localStorage.setItem('userId', this.info.userId);
          this.gettitlelist();
          this.getlist();
        } else {
          console.log('没有用户数据');
          sessionStorage.removeItem('dzmmtoken');
          this.$router.replace({
            path: '/login'
          });
        }
      });
    },
    //点赞
    like(id, index, like) {
      console.log(this.list);
      upvote({
        articleId: id,
        //文章id
        userId: this.info.userId
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          let list = this.list;
          list.map(item => {
            console.log(item);
            if (id == item.id) {
              if (item.like) {
                item.upvote = item.upvote - 1;
              } else {
                item.upvote = item.upvote + 1;
              }
              item.like = !item.like;
            }
          });
          this.list = list;

          // if(!like){
          //     this.list[index].upvote = this.list[index].upvote + 1
          //     this.list[index].like =true
          // }else {
          //     this.list[index].upvote = this.list[index].upvote - 1
          //     this.list[index].like =false
          // }
          console.log(this.list[index].like);

          // this.list = []
          // this.pageNum = 1,
          // this.getlist()
        }
      });
    },

    //获取数据列表
    getlist() {
      dataController({
        articleCategoryId: this.articleCategoryId,
        userId: this.info.userId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        console.log(res);
        this.flag = true;
        if (res.code == 200) {
          res.rows.map(item => {
            // item.avatar = 'https://www.allslug.com/prod-api/' + item.avatar
            // item.img = 'https://www.allslug.com/prod-api/' + item.img
            item.avatar = this.$store.state.imgurl + item.avatar;
            item.img = this.$store.state.imgurl + item.img;
          });
          let list = this.list.concat(res.rows);
          this.list = list;
          this.total = res.total;
          console.log(this.list);
        }
      });
    },
    //获取分类数据
    gettitlelist() {
      dataControllertitle().then(res => {
        if (res.code == 200) {
          this.titlelist = res.data.articleCategoryList;
          console.log(this.titlelist);
        }
        console.log(res);
      });
    },
    //点击详情
    godetail(id) {
      console.log(id);
      this.$router.push({
        path: '/information/detail',
        query: {
          id
        }
      });
    },
    //点击分类
    changetitle(index) {
      this.activeindex = index;
      this.articleCategoryId = this.titlelist[index].id;
      this.list = [];
      this.getlist();
    },
    // 滚动到底部加载数据
    scrollBottom() {
      console.log('触发滚动');
      let el = document.getElementById("container");
      let windowHeight = window.screen.height;
      // console.log(windowHeight);
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || document.getElementById("logBook").scrollTop;
      // console.log(scrollTop);
      let contentHeight = el.clientHeight + 10;
      // console.log(contentHeight);
      let toBottom = contentHeight - windowHeight - scrollTop;
      // console.log(toBottom);
      if (this.list.length < this.total && toBottom < 50) {
        if (this.flag) {
          this.flag = false;
          this.pageNum = this.pageNum + 1;
          this.getlist();
        }
      }
    }
  }
};