import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-55547d79"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content",
  "scroll-y": "",
  bindscrolltolower: "getlist"
};
const _hoisted_2 = {
  class: "badgetitle"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "warp",
  id: "container"
};
const _hoisted_5 = ["src", "onClick"];
const _hoisted_6 = {
  class: "title"
};
const _hoisted_7 = {
  class: "bottom"
};
const _hoisted_8 = {
  class: "bottom-left"
};
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "bottom-right"
};
const _hoisted_11 = ["onClick"];
const _hoisted_12 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "资料列表"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.titlelist, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass($data.activeindex == index ? 'active' : ''),
      onClick: $event => $options.changetitle(index)
    }, _toDisplayString(item.name), 11, _hoisted_3);
  }), 128))]), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "box",
      key: index
    }, [_createElementVNode("img", {
      class: "bigimg",
      src: item.img,
      onClick: $event => $options.godetail(item.id)
    }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, [_createElementVNode("img", {
      src: item.avatar
    }, null, 8, _hoisted_9), _createElementVNode("div", null, _toDisplayString(item.createBy), 1)]), _createElementVNode("div", _hoisted_10, [item.like ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      onClick: $event => $options.like(item.id),
      src: "https://meco-1259435766.cos.ap-shanghai.myqcloud.com/tc/home/tester/experience/dz2.png"
    }, null, 8, _hoisted_11)) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      onClick: $event => $options.like(item.id, index, item.like),
      src: "https://meco-1259435766.cos.ap-shanghai.myqcloud.com/tc/home/tester/experience/dz1.png"
    }, null, 8, _hoisted_12)), _createElementVNode("div", null, _toDisplayString(item.upvote), 1)])])]);
  }), 128))])])], 64);
}